.results-single-details-page {
  .header {
    font-size: 24px;
  }

  .content {
    padding-top: 24px;

    &__user-data {
      margin-bottom: 32px;

      .data {
        margin-bottom: 24px;

        &__header {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        &__content {
          font-size: 18px;
          line-height: 1.33;
          opacity: 0.5;
          margin-bottom: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    &__user-nss {
      .data {
        &__header {
          font-weight: bold;
          margin-bottom: 8px;
        }

        &__content {
          font-size: 32px;
          opacity: 0.5;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .header {
      font-size: 48px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      padding-top: 72px;

      &__user-data,
      &__user-nss {
        max-width: 250px;
      }

      &__user-data {
        margin-bottom: 0px;

        .data {
          margin-bottom: 44px;
        }
      }
    }
  }
}
