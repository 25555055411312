.home-page--support {
  flex-direction: row-reverse;

  .home-page__image {
    background-image: url(../../../img/photos/support-sm.png);
  }

  .home-page__info {
    &__content {
      padding: 32px 28px;

      .heading {
        margin-bottom: 44px;

        &__header {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 24px;

          &::after {
            content: " ";
            display: block;
            background-color: $default;
            height: 2px;
            width: 64px;
            margin-top: 14px;
          }
        }

        &__sub-header {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }

      .body {
        .regions,
        .contacts,
        .whatsapp {
          p,
          a {
            margin-bottom: 24px;
            display: block;
            height: 24px;
            line-height: 1.5;

            &:last-child {
              margin-bottom: 0px;
            }

            img {
              width: 24px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .home-page__image {
      background-image: url(../../../img/photos/support.png);
    }

    .home-page__info {
      &__content {
        padding: 0px;

        .heading {
          margin-bottom: 48px;

          &__header {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 40px;

            &::after {
              width: 76px;
            }
          }

          &__sub-header {
          }
        }
      }
    }
  }
}
