.personnel-info {
  .data {
    margin-bottom: 24px;

    &__header {
      font-size: 12px;
      opacity: 0.3;
      margin-bottom: 8px;
    }

    &__content {
      line-height: 1.33;
      margin-bottom: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &--inverse {
    @extend .personnel-info;

    .data {
      &__header {
        opacity: 1;
        font-weight: bold;
      }

      &__content {
        opacity: 0.54;
      }
    }
  }
}

.page-header {
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 1.33;
}
