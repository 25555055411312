.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;

  &--success {
    &,
    &:hover {
      border: solid 1px $green;
      background-color: $green;
      color: $white;
    }

    &:focus,
    &:active {
      background-color: darken($green, 5%) !important;
      color: $white !important;
      box-shadow: 0 0 0 0.2rem rgba($color: $green, $alpha: 0.15) !important;
    }
  }

  &--stateless {
    border: solid 1px $pale-grey;
    background-color: $white;
    color: $default;
  }

  &--danger {
    &,
    &:hover {
      border: solid 1px $red;
      background-color: $red;
      color: $white;
    }

    &:focus,
    &:active {
      background-color: darken($red, 5%) !important;
      color: $white !important;
      box-shadow: 0 0 0 0.2rem rgba($color: $red, $alpha: 0.15) !important;
    }
  }

  &--danger-outlined {
    &,
    &:hover {
      border: solid 1px $pale-red;
      background-color: $white;
      color: $pale-red;
    }

    &:focus,
    &:active {
      background-color: $white !important;
      color: $pale-red !important;
      box-shadow: 0 0 0 0.2rem rgba($color: $pale-red, $alpha: 0.15) !important;
    }
  }
}
