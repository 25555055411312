.payments-page {
  .available-methods {
    &__cards {
      flex-wrap: wrap;

      .light-card {
        margin-bottom: 24px;
        flex-basis: auto;
      }
    }
  }

  @media (min-width: 768px) {
    .contained {
      max-width: 300px;
    }

    .available-methods {
      margin-top: 96px !important;

      &__cards {
        .light-card {
          margin-right: 24px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .available-methods {
      &__cards {
        .light-card {
          align-self: flex-start;
        }
      }
    }
  }
}
