label {
  font-size: 12px;
  letter-spacing: -0.3px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 32px;
}

.form-control {
  border: solid 1px $light-periwinkle !important;
  padding: 0px 10px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: none !important;
  outline: none;

  &:focus {
    border-color: lighten($green, 40%) !important;
  }

  &.--adorned {
    border: none !important;
    box-shadow: none;
  }

  &.--border-none {
    border: none !important;
  }
}

.input-container {
  border: solid 1px $light-periwinkle;
  padding: 0px 10px;
  border-radius: 4px;

  &:focus-within {
    border-color: lighten($green, 40%);
  }

  .form-control {
    border: none;
    height: 46px;
  }
}

.custom-control {
  $border: rgba(
    $color: #000000,
    $alpha: 0.25,
  );
  $checkmark: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.091 1L5.091 8L1.90918 4.81818' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

  &-label {
    color: rgba($color: #000000, $alpha: 0.6);
    line-height: 1.5;
    font-size: 16px;
    padding-left: 16px;

    &::before {
      border-color: $border;
    }

    &::before,
    &::after {
      width: 26px;
      height: 26px;
      box-shadow: none;
    }

    span {
      font-size: 18px !important;
      margin-top: 4px;
      display: block;
    }
  }

  .custom-control-input {
    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: $white;
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba($color: #000000, $alpha: 0.05);
    }
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $border;
    }
  }

  &.custom-checkbox {
    .custom-control-label {
      &::before {
        border-color: $blue;
      }
    }
    .custom-control-input:checked {
      & ~ .custom-control-label::before {
        border-color: $blue;
        background-color: $blue;
      }
      & ~ .custom-control-label::after {
        background-image: $checkmark;
      }
    }
  }

  &.custom-radio {
    .custom-control-label {
      padding-left: 8px;

      &::before,
      &::after {
        width: 24px;
        height: 24px;
      }

      span {
        margin-top: 3px;
      }
    }

    .custom-control-input:checked {
      & ~ .custom-control-label::before {
        border-color: $green;
        background-color: $green;
      }
      & ~ .custom-control-label::after {
        background-image: $checkmark;
      }
    }
  }
}
