.card {
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px $pale-grey;
}

.light-card {
  height: 48px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.active {
    $active: rgba(191, 239, 195, 0.5);
    background: $active;
    border-color: $active;
  }

  img {
    height: 18px;
    display: block;
    margin-right: 8px;
    display: inline-block;

    &.--expresspay,
    &.--ghlink {
      height: 30px;
    }
  }

  span {
    font-size: 14px;
  }
}

.side-card {
  width: 100%;

  .card-body {
    padding: 0px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: solid 1px lighten($pale-grey, 2.5%);

    &__header {
      font-size: 18px;
      font-weight: bold;
      margin: 0px;
    }
  }

  .body {
    padding: 24px;
  }

  @media (min-width: 768px) {
    .heading {
      padding: 16px 32px;
    }
    .body {
      padding: 24px 32px;
    }
  }
}
