.home-page--requirements {
  flex-direction: row-reverse;

  .home-page__image {
    background-image: url(../../../img/photos/requirements-sm.png);
  }

  .home-page__info {
    &__content {
      padding: 32px 28px;

      .heading {
        margin-bottom: 44px;

        &__header {
          margin-bottom: 24px;
          font-size: 18px;
          font-weight: bold;

          &::after {
            content: " ";
            display: block;
            background-color: $default;
            height: 2px;
            width: 64px;
            margin-top: 14px;
          }
        }

        &__sub-header {
          font-weight: 400;
          margin-bottom: 0px;
          font-size: 16px;
        }
      }

      .body {
        .requirements {
          &__details {
            display: flex;
            margin-bottom: 32px;

            &__icon {
              width: 17px;
              height: 17px;
              flex: 0 0 17px;
              display: block;
              margin-right: 12px;
              margin-top: 4px;
            }

            &__content {
              margin-bottom: 0px;
              line-height: 1.5;
            }

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .home-page__image {
      background-image: url(../../../img/photos/requirements.png);
    }

    .home-page__info {
      &__content {
        padding: 0px;

        .heading {
          &__header {
            font-size: 48px;
            margin-bottom: 8px;
            font-weight: 400;

            &::after {
              display: none;
            }
          }

          &__sub-header {
            font-weight: bold;
          }
        }
      }
    }
  }
}
