.home-page {
  &__info {
    width: 100%;
    background-color: $white;
  }

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 256px;
    display: flex;
    padding: 24px 32px;
  }

  @media (min-width: 768px) {
    &__image {
      padding: 56px 90px;
      background-position: left;
      width: 100%;
      height: 100vh;
    }

    &__info {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 90px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__content {
        margin: auto 0;
        max-width: 600px;
      }
    }
  }

  @media (min-width: 991px) {
    & {
      display: flex;
      height: 100%;
      width: 100%;
    }

    &__image {
      width: 40%;
    }

    &__info {
      width: 60%;
    }
  }
}

.close-button {
  color: $default;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 24px;

  &:hover {
    color: $default;
  }

  span {
    display: block;
    font-size: 12px;
    margin-right: 10px;
  }

  img {
    width: 24px;
  }

  @media (min-width: 768px) {
    & {
      height: 46px;

      span {
        font-size: 24px;
        margin-right: 20px;
      }
      img {
        width: 46px;
      }
    }
  }
}

// variants | using .home-page
@import "home/home";
@import "home/requirements";
@import "home/support";

// others
@import "home/search";
@import "home/results";
@import "home/details";
@import "home/wrong-info";
