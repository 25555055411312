// variables
$navigation-border: solid 1px
  lighten(
    $color: lighten(#000000, 80%),
    $amount: 10%,
  );

.navigation {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: $navigation-border;
  margin-bottom: 24px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      &__img {
        height: 24px;
      }
    }

    .user {
      display: flex;
      align-items: center;

      &__name {
        display: none;
        color: $default;
        opacity: 0.5;
        margin-bottom: 0px;
        margin-right: 16px;
      }

      &__image {
        width: 32px;
        height: 32px;
        background-color: $grey;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        opacity: 0.5;

        img {
          max-width: 100%;
        }
      }

      &__logout {
        color: $red;
      }
    }
  }
}

.footer {
  margin-top: 90px;

  &__content {
    position: relative;
    padding-top: 16px;
    padding: 16px;
    border-top: solid 1px rgba(0, 0, 0, 0.15);

    &__logo {
      height: 32px;
    }
  }
}

@media (min-width: 768px) {
  .navigation {
    border-bottom: none;
    padding-top: 0px;
    padding-bottom: 48px;
    margin-bottom: 0px;

    &__container {
      padding: 32px 0px;
      border-bottom: $navigation-border;

      .logo {
        &__img {
          height: 42px;
        }
      }

      .user {
        &__name {
          display: block;
        }

        &__image {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .footer {
    &__content {
      &__logo {
        height: 48px;
      }

       
    }
  }
}
