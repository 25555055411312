.alert {
  border-radius: 4px;
  padding: 24px 12px;
  display: flex;
  border: none;

  .close {
    display: none;
  }

  &__icon {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 16px;
    flex: 0 0 20px;
    margin-top: 4px;
  }

  &__content {
    line-height: 1.33;
    margin-bottom: 0px;
    font-size: 12px;
  }

  &__close {
    margin-left: auto;
    display: block;
    width: 22px;
    height: 22px;
    background-image: url(../img/icons/close-bold.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }

  &--danger {
    background-color: rgba($color: $red, $alpha: 0.1);
    color: $red;
  }

  &--primary {
    background-color: rgba($color: $blue, $alpha: 0.1);
    color: $blue;
  }

  &--success {
    background-color: rgba($color: $green, $alpha: 0.1);
    color: $green;
  }

  @media (min-width: 768px) {
    & {
      padding: 24px;
    }

    &__content {
      font-size: 16px;
    }
  }
}
