// colors
$default: #000;
$green: #20b038;
$light-green: #eafbec;
$red: #ff3c3c;
$pale-red: #fd6666;
$pale-grey: #e5e5e5;
$grey: #c4c4c4;
$blue: #2faee5;
$white: #fff;
$light-periwinkle: #e6e6e6;
$greeny-grey: #ecf3ec;

$colors: () !default;

$colors: map-merge(
  (
    "default": $default,
    "green": $green,
    "red": $red,
    "pale-red": $pale-red,
    "pale-grey": $pale-grey,
    "grey": $grey,
    "light-green": $light-green,
    "blue": $blue,
    "white": $white,
    "light-periwinkle": $light-periwinkle,
    "greeny-grey": $greeny-grey,
  ),
  $colors
);

// grids
$grids: (
  (
    name: "sm",
    grid: 0,
  ),
  (
    name: "md",
    grid: 768,
  ),
  (
    name: "lg",
    grid: 992,
  ),
  (
    name: "xl",
    grid: 1200,
  )
);

// font weights
$weights: (400, 700);
