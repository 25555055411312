// import partials
@import "functions";
@import "variables";
@import "resets";
@import "utils";

@import "table";
@import "form";
@import "buttons";
@import "cards";
@import "toast/main";
@import "layout";
@import "alerts";

// media queries partials
@import "sm";

@media (min-width: 768px) {
  @import "md";
}

@media (min-width: 992px) {
  @import "lg";
}

@media (min-width: 1200px) {
  @import "xl";
}

// components
@import "steps";

// pages
@import "pages/home";
@import "pages/dashboard";
