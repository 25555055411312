.certificate-page {
  .card {
    max-width: 384px;
  }

  .socials {
    a {
      display: block;
      width: 28px;
      height: 28px;
      background-color: $blue;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 4px;
    }
  }

  @media (min-width: 991px) {
    & {
      height: 100vh;
    }
  }
}
