.table {
  margin-bottom: 0px;
  white-space: nowrap;

  thead {
    tr {
      th {
        white-space: nowrap;
        padding: 0px;

        .th {
          padding: 24px;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba(191, 239, 195, 0.39) !important;
      }
      td {
        padding: 0px;

        .td {
          height: 77px;
          display: flex;
          align-items: center;
          line-height: 1.3;
          cursor: pointer;
          padding-left: 32px;
          padding-right: 32px;
        }
      }
    }
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(234, 234, 234, 0.39);
  }

  // compact table
  &--compact {
    white-space: normal;

    .td {
      display: block !important;
      height: auto !important;
      padding: 16px 32px;

      .table__data {
        display: flex;
        margin-bottom: 16px;

        &__header {
          font-weight: bold;
        }

        &__header,
        &__data {
          width: 50%;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    & {
      white-space: normal;
    }

    tbody {
      tr {
        td {
          .td {
            padding-left: 24px;
            padding-right: 24px;
          }
        }
      }
    }
  }
}

.flex-table {
  .data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__header {
      margin: 0px;
    }

    &__content {
      margin: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
