.steps-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.steps {
  white-space: nowrap;

  &__step {
    display: inline-block;
    position: relative;
    vertical-align: top;

    &__icon {
      width: 64px;
      height: 64px;
      margin: 0px auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;

      span {
        width: 32px;
        height: 32px;
        border: solid 1px rgba($color: #000000, $alpha: 0.25);
        color: $default;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        background-color: $white;
      }
    }

    &__title {
      font-size: 14px;
      text-align: center;
      z-index: 1;
      white-space: normal;
    }

    &__tail {
      position: absolute;
      height: 1px;
      background-color: $default;
      opacity: 0.2;
      width: 75%;
      top: 0px;
      margin-top: 32px;
      right: -37.5%;
      z-index: -1;
    }

    &.--active {
      .steps__step__icon {
        span {
          background-color: #dff7e1;
          border-color: $green;
        }
      }
    }

    &.--completed {
      .steps__step__icon {
        span {
          background-color: $green;
          color: $white;
          border-color: $green;
        }
      }

      .steps__step__title {
        color: $green;
      }
    }

    &.--icon {
      .steps__step__icon {
        span {
          background-color: transparent;
          border: none;

          img {
            height: 32px;
          }
        }
      }
      &.--active {
        .steps__step__title {
          color: $green;
        }
      }
    }
  }

  @media (min-width: 768px) {
    &__step {
    }
  }
}
