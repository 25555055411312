.search-page {
  &__content {
    &__body {
      .btn {
        width: 100%;
      }

      .footer {
        margin-top: 90px;
      }
    }
  }

  @media (min-width: 768px) {
    & {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      margin: auto 0;

      &__body {
        .btn {
          max-width: 250px;
        }

        .footer {
          margin-top: 144px;
        }
      }
    }
  }
}
