.home-page--home {
  .home-page__image {
    background-color: $light-green;
    background-image: url(../img/photos/home.png);
  }

  .home-page__info {
    &__content {
      .heading {
        margin-bottom: 32px;
        padding: 48px 32px;
        background-color: $greeny-grey;

        img {
          height: 32px;
          display: block;
          margin-bottom: 28px;
        }

        &__header {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 8px;
          letter-spacing: -1px;
        }

        &__sub-header {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0px;
        }
      }
      .body {
        padding-left: 32px;
        padding-right: 32px;

        .search-form {
          &__input {
            width: 100%;
            height: 64px;
            outline: none;
            box-shadow: none;
            padding-left: 16px;
            padding-right: 16px;
            font-size: 14px;
          }

          &__button {
            width: 100%;
            height: 56px;
          }
        }
      }

      .footer {
        padding-left: 32px;
        padding-right: 32px;

        margin-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media (min-width: 768px) {
    .home-page__info {
      background-color: $white;

      &__content {
        max-width: 560px;

        .heading {
          margin-bottom: 84px;
          padding: 0px;
          background-color: $white;

          img {
            height: 42px;
            margin-bottom: 64px;
          }

          &__header {
            font-size: 32px;
            margin-bottom: 16px;
          }

          &__sub-header {
            font-size: 24px;
          }
        }

        .body {
          padding: 0px;

          .search-form {
            &__input {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              padding-left: 24px;
              padding-right: 24px;
            }

            &__button {
              width: 172px;
              height: 64px;
            }
          }
        }

        .footer {
          padding: 0px;
          margin-top: 144px;
        }
      }
    }
  }

  @media (min-width: 991px) {
    .home-page__image {
      background-position: center;
      width: 45%;
    }

    .home-page__info {
      width: 55%;
    }
  }
}
