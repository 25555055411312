.consent-initial {
  max-width: 480px;

  .data {
    margin-bottom: 48px;

    &__header {
      font-size: 12px;
      opacity: 0.3;
      margin-bottom: 8px;
      line-height: 1.2;

      &.--darker {
        opacity: 0.8;
      }
    }

    &__content {
      margin-bottom: 0px;
      line-height: 1.33;
      opacity: 0.8;
    }

    &:last-child {
      .data__content {
        opacity: 1;
      }
    }
  }
}
